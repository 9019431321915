/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/demirbank';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  ListTransactionsSuccess,
  listTransactionsFailed,
  listTransactionsSucceeded,
} from './actions';
import { listPaymentsQuery } from './queries';

export default function listTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactions(action, state$.value.transactions?.size).catch((error) => listTransactionsFailed(error)),
    ),
  );
}

async function listTransactions(
  { currentPage, filter, searchString }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listPayments: { total, payments },
    },
  } = await graphQLClient.query({
    query: listPaymentsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listTransactionsSucceeded(total, payments);
}

export function listTransactionsUserEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactionsUser(action, state$.value.transactions?.size).catch((error) => listTransactionsFailed(error)),
    ),
  );
}

export async function listTransactionsUser(
  { currentPage, filter, searchString }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listTransactions: { total, payments },
    },
  } = await graphQLClient.query({
    query: listPaymentsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listTransactionsSucceeded(total, payments);
}
