/* External dependencies */
import gql from 'graphql-tag';

export const listPaymentsQuery = gql`
  query listPayments($input: ListPaymentsInput!) {
    listPayments(input: $input) {
      total
      payments {
        amount
        clientId
        provider
        id
        fees {
          clientFee
          clientFeeProfit
          serviceRewardFee
          serviceRewardFeeProfit
        }
        requestDate
        status
        service {
          id
        }
        transactionDate
        errorMessage
      }
    }
  }
`;
