/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ListTransactionsAction, ListTransactionsActionTypes, ListTransactionsFilter } from './actions';
import { Transaction } from './types';

export interface TransactionsState {
  currentPage: number;
  endDate?: number;
  error?: Error;
  filter?: ListTransactionsFilter;
  loading?: boolean;
  locale?: string;
  payments: Transaction[];
  searchString: string;
  selectedTransactions?: [];
  size: number;
  startDate?: number;
  total: number;
}

const size: number = 20;

export const initialTransactionsState: TransactionsState = {
  currentPage: 1,
  filter: {},
  loading: true,
  locale: 'RU',
  payments: [],
  searchString: '',
  selectedTransactions: [],
  size,
  total: 0,
};

export default function transactionsReducer(state = initialTransactionsState, action: ListTransactionsAction) {
  switch (action.type) {
    case ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST:
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        payments: { $set: action.payments },
        total: { $set: action.total },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE:
      return update(state, {
        startDate: { $set: action.startDate },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE:
      const { endDate } = action;

      return update(state, {
        endDate: { $set: endDate },
      });

    default:
      return state;
  }
}
